<!-- src/components/Header.vue -->
<template>
  <header class="header">
    <img src="@/assets/logo-punto.png" alt="Logo" class="logo" />
    <div class="info-container">
      <div class="info-item">
        <strong>Cliente:</strong> {{ userPWAData?.razonSocial }}
      </div>
      <div class="info-item">
        <strong>Centro de Costo:</strong> {{ userPWAData?.nombreCC }}
      </div>
      <div class="info-item">
        <strong>Punto de Envío:</strong> {{ userPWAData?.DireccionEntrega }}
      </div>
    </div>
    <div class="logout-button" @click="cerrarSesion">
      <font-awesome-icon icon="user" class="custom-icon" />
      <span class="texto">{{userPWAData.nombres}}</span>
    </div>
  </header>
</template>

<script>
import Cookies from 'js-cookie';
export default {

  name: 'SearchCliente',

  props: {
  },

  methods: {
    cerrarSesion() {
      console.log('Sesión cerrada');



      setTimeout(() => {
        window.location.reload();
      }, 200);

      this.$router.push('/');

    }
  },

  data() {
    return {
      userPWAData: Cookies.get('userPWA') ? JSON.parse(Cookies.get('userPWA')) : null,
    }
  }

}
</script>

<style scoped>
.header {
  background-color: #FFCB05;
  color: #233A7A;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 98%;
  text-align: center;
  justify-content: space-around;
}

.logo {
  width: 202px;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  margin-bottom: 15px;
}

.info-item {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.4;
}

.logout-button {
  display: flex;
    align-items: center;
    color: #233A7A;
    cursor: pointer;
    flex-direction: column;

}

.custom-icon {
  font-size: 35px;
  color: #fff;
  margin-right: 8px;
}

.texto {
  font-size: 15px;
  color: #fff;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 104%;
    position: relative;
  }

  .logo {
    width: 100px;
    margin-bottom: 8px;
  }

  .info-container {
    margin-bottom: 10px;
  }

  .logout-button {
    margin-top: 10px;
  }

  .custom-icon {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .logo {
    width: 115px;
  }

  .custom-icon {
    font-size: 25px;
  }

  .texto {
    font-size: 13px;
  }

  .info-item {
    font-size: 14px;
  }
}
</style>
