import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { faTrash, faXmark, faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker'


const app = createApp(App);
library.add(faTrash);
library.add(faXmark);
library.add(faUser);
library.add(faLock);
app.use(router);
app.use(Toast);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
