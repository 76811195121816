<template>
  <SearchCliente :nombrCC="nombrCC" :clienNom="clienNom" :centrosPe="centrosPe" />
  <div class="logged-in-container">

    <!-- <img src="@/assets/logo.png" alt="Logo" class="logo" /> -->
    <!-- <h1>El Punto Cadena</h1> -->
     <div class="conte">
      <router-link to="/order" class="view-order-button">Ver Pedido</router-link>
    <div class="order-summary">
          <p class="total-price">Total items: {{ orders.length }}</p>
        </div>
     </div>

    <!-- Buscador de productos -->
    <div class="search-container">
      <input type="text" v-model="searchQuery" @input="searchProducts" placeholder="Buscar productos..." />
    </div>

    <!-- Tarjetas de productos -->
    <div class="product-cards">
      <div v-if="products.length === 0" class="no-products">
        No se encontraron productos.
      </div>
      <div v-for="product in products" :key="product.id" class="product-card">

        <img :src="'https://ep1.elpunto.com.co/puntocadenaimages/' +
          product.fotoEcom?.split(',')[0]
          " alt="Imagen del producto" @error="onImageError" class="product-image" loading="lazy"/>

        <div class="product-info">
          <h3 class="product-title">{{ product.name }}</h3>
          <p class="product-description">{{ product.description }}</p>
          <div class="product-price-info">
            <p class="product-price">${{ formatcantidad((product.price * product.iva / 100) + product.price) }}</p>
            <p class="product-disponible">
              Disp: {{ product.availibilityCount }}
            </p>
          </div>
          <!-- Campo de cantidad con botones de incremento y decremento -->
          <div class="cantidad-container">
            <button @click="decreasecantidad(product)" class="cantidad-button">
              -
            </button>
            <input type="number" v-model.number="product.cantidad" min="1" :max="product.availibilityCount"
              placeholder="Cantidad" class="cantidad-input" />
            <button @click="increasecantidad(product)" class="cantidad-button">
              +
            </button>
          </div>
          <button class="add-to-list-button" @click="addToOrder(product)">
            Agregar al pedido
          </button>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

import SearchCliente from '../components/SearchCliente.vue'
import { ref } from "vue";
import { searchProducts as apiSearch } from "@/router/router";
import { useToast } from "vue-toastification";
import Cookies from 'js-cookie';

export default {
  components: {
    SearchCliente
  },
  name: "LoggedInView",
  setup() {
    const searchQuery = ref("");
    const products = ref([]);
    const toast = useToast();
    const nombrCC = ref("");
    const clienNom = ref("");
    const centrosPe = ref("");
    const userPWAData = ref(Cookies.get('userPWA') ? JSON.parse(Cookies.get('userPWA')) : null);
    const orders = ref(JSON.parse(localStorage.getItem('order')) || []);
    
    const searchProducts = async () => {
      try {
        const response = await apiSearch(searchQuery.value);
        products.value = response.data.slice(0, 10).map(product => {
          initializeProduct(product);
          return product;
        });
       

      } catch (error) {
        console.error("Error al buscar productos:", error);
      }
    };



    const addToOrder = (product) => {
      const cantidad = product.cantidad || 1;
      const precio2 = product.price === null ? 0 : product.price;
      const usuModifica = userPWAData.value.userName;
      const codPrecio = product.codprecioP;
      const codigoProducto = product.id;
      const nota = '';
      const userModify = userPWAData.value.userName;
      let order = JSON.parse(localStorage.getItem("order")) || [];
      const existingProduct = order.find((item) => item.id === product.id);

      if (existingProduct) {
        existingProduct.cantidad += cantidad;
      } else {

        order.push({ ...product, cantidad, precio2, usuModifica, codPrecio, nota, codigoProducto, userModify });
      }

      orders.value = order;

      localStorage.setItem("order", JSON.stringify(order));
      toast.success("Producto agregado correctamente!", {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        showCloseButtonOnHover: true,
      });
    };

    const onImageError = (event) => {
      event.target.src =
        "https://ep1.elpunto.com.co/puntocadenaimages/000000000.jpg";
    };

    const formatcantidad = (cantidad) => {
      return Number(cantidad).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    };

    const initializeProduct = (product) => {
      product.cantidad = 1;
    };


    const decreasecantidad = (product) => {
      if (product.cantidad > 1) {
        product.cantidad -= 1;
      }
    };

    const increasecantidad = (product) => {
      if (product.cantidad < product.availibilityCount) {
        product.cantidad += 1;
      }
    };

    return {
      searchQuery,
      products,
      searchProducts,
      addToOrder,
      onImageError,
      formatcantidad,
      increasecantidad,
      decreasecantidad,
      initializeProduct,
      nombrCC,
      clienNom,
      centrosPe,
      userPWAData,
      orders

    };

  },

};
</script>

<style scoped>
.logged-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
  background: url("@/assets/imagenFondo.png") no-repeat center center;
  background-size: cover;

}

.logo {
  width: 80px;
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #333;
}

.search-container {
  margin: 1rem;
  width: 100%;
  max-width: 500px;
}

input[type="text"] {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.product-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  min-height: 61vh;
  box-sizing: border-box;
  height: 58vh;
  overflow: auto;
}

.product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 1.5rem;
  width: 100%;
  max-width: 340px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.product-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: #333;
}

.product-description {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.product-price-info {
  margin: 1rem 0;
}

.product-price {
  font-weight: bold;
  color: #233A7A;
  font-size: 1.2rem;
}

.product-disponible {
  color: #999;
  font-size: 0.9rem;
}

.add-to-list-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #233A7A;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-to-list-button:hover {
  background-color: #FFCB05;
}

.no-products {
  margin-top: 1rem;
  color: #999;
}

.cantidad-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.cantidad-button {
  background-color: #233A7A;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color 0.3s ease;
}

.cantidad-button:hover {
  background-color: #FFCB05;
}

.cantidad-input {
  width: 5rem;
  text-align: center;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 0.5rem;
  box-sizing: border-box;
}



/* Responsive Styles */
@media (max-width: 600px) {
  .logo {
    width: 60px;
  }

  h1 {
    font-size: 1rem;
  }

  .search-container {
    max-width: 90%;
  }

  .product-card {
    max-width: 100%;
  }

  .product-title {
    font-size: 0.95rem;
  }

  .product-description {
    font-size: 0.85rem;
  }
  .logged-in-container{
    width: 105%;
  }
}

.view-order-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #233A7A;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-order-button:hover {
  background-color: #131f3f;
}
.conte{
  display: flex;
  flex-direction: row-reverse;
}
.order-summary{
  position: relative;
    top: 14px;
    left: -39px;
}

header,
footer {
  position: sticky;
  z-index: 10;
}

header {
  top: 0;
}

footer {
  bottom: 0;
}

</style>
