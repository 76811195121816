<template>
  <div class="login-container">
    <div class="login-form">
      <img src="@/assets/logo.png" alt="Logo" class="logo" />
      <!-- <h1>Login</h1> -->
      <form @submit.prevent="login">
        <div class="form-group">
          <!-- <label for="username">Username</label> -->
          <div class="icon">
            <font-awesome-icon icon="fa-solid fa-user" />
          </div>
          <input type="text" id="userName" v-model="userName" placeholder="Nombre de Usuario" required />

        </div>
        <div class="form-group">
          <div class="iconPas">
            <font-awesome-icon icon="fa-solid fa-lock" />
          </div>
          <input type="password" id="contrasena" v-model="contrasena" placeholder="Contraseña" required />
        </div>
        <button type="submit">Iniciar sesión</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { login as apiLogin } from '@/router/router';
import Cookies from 'js-cookie';
export default {
  name: 'UserLogin',
  setup() {
    const userName = ref('');
    const contrasena = ref('');
    const toast = useToast();
    const router = useRouter();

    const login = async () => {
      try {
        const resp = await apiLogin({
          userName: userName.value,
          contrasena: contrasena.value
        });

        if (typeof resp.data === 'string') {
          toast.error(resp.data, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            showCloseButtonOnHover: true
          });
          return
        }

        toast.success("Inicio de sesión correctamente!", {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          showCloseButtonOnHover: true
        });
        Cookies.set('userPWA', JSON.stringify(resp.data));

        localStorage.setItem('authToken', resp.data.token);
        router.push('/search');

      } catch (error) {
        let errorMessage = "Usuario o contraseña errados. Valida nuevamente";

        if (error.response && typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        }

        toast.error(errorMessage, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          showCloseButtonOnHover: true
        });


        console.error('Error en el inicio de sesión:', error);
      }
    };


    return {
      userName,
      contrasena,
      login
    };
  }
};
</script>

<style scoped>
.login-container {
  justify-content: center;
  align-items: center;
  height: 93.8vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  background: url(/src/assets/imagenFondo.png) no-repeat center center;
  background-size: cover;
  min-height: 93.8vh;
  ;
  box-sizing: border-box
}

.login-form {
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.logo {
  width: 186px;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

form label {
  display: block;
  margin-bottom: 0.5rem;
}

form input {
  width: 50%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #FFCB05;
  color: #007BBD;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #c9a702;
}

.fa-user {
  position: relative;
  color: white;
  top: 7px;
}

.icon {
  background-color: #233A7A;
  height: 32px;
  top: 32px;
  position: relative;
  border-radius: 8px 1px 1px 9px;
  width: 28px;
  left: 16%;
}

.fa-lock {
  position: relative;
  color: white;
  top: 7px;
}

.iconPas {
  background-color: #233A7A;
  height: 32px;
  top: 32px;
  position: relative;
  border-radius: 8px 1px 1px 9px;
  width: 28px;
  left: 16%;
}

@media (max-width: 380px) {
  .iconPas {
    left: 14%;
  }

  .icon {
    left: 14%;
  }
}

@media (max-width: 300px) {
  .iconPas {
    left: 10%;
  }

  .icon {
    left: 10%;
  }
}
</style>
