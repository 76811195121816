<template>
  <footer class="footer">
    <nav class="menu">
      <a v-if="currentRoute === '/'"
           href="https://www.elpunto.com.co"
           target="_blank"
           rel="noopener noreferrer" style="color: 233a7a;">www.elpunto.com.co</a>
      <!-- Condicionales basadas en la ruta actual -->
      <router-link v-if="currentRoute === '/search'" to="/logged-in">Siguiente</router-link>
      <router-link v-if="currentRoute === '/logged-in'" to="/order">Siguiente</router-link>
      
      <router-link v-if="currentRoute === '/logged-in'" to="/search">Anterior</router-link>
      <router-link v-if="currentRoute === '/order'" to="/logged-in">Anterior</router-link>
    </nav>
  </footer>
</template>

<script>

export default {
  name: 'FooterP',

  computed: {
    currentRoute() {

      return this.$route.path;
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #FFCB05;
  color: #fff;
  padding: 15px;
  text-align: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 97.5%;
}

.menu {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.menu a {
  text-decoration: none;
  font-weight: bold;
  color: #233A7A;
  transition: color 0.3s;
}

.menu a:hover {
  color: #0a2540;
}

</style>
